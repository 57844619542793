.draganddrop {
    border: 1px solid #0000004D;
    margin: 21px;
    margin-bottom: 4px;
    height: 19vh;
    border-radius: 7px;
    border-style: dashed;
    background: #f7f5f56e;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
}
.uploadIcon{
    background: #d0d0ea;
    color: #0f78ec;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.draganddropContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.fileList{
    margin-right: 10vh;
}
.sucessProgess{
    background-color: green;
}

.dropboxfooter {
    display: flex;
    justify-content: space-between;
    margin-left: 22px;
    margin-right: 22px;
}
.sucessIcon{
    font-size: 51px !important;
    color: green;
}
.failureIcon{
    font-size: 51px !important;
    color: red;
}
.popupInnerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spacingTextImport {
    width: 72%;
    display: flex;
    justify-content: space-between;
}
.hr{
    width:100%;
    text-align:left;
    margin-left:0    

}
.fileListContainer{
    margin-bottom: 14vh;
}


ul { list-style-type: none; }